const dtStart = /DTSTART;(VALUE=DATE:(?<date>\d*)|TZID=(?<tzid>.*):(?<tzDate>\d*)T(?<tzTime>\d*))/gm;
const dtEnd = /DTEND;(VALUE=DATE:(?<date>\d*)|TZID=(?<tzid>.*):(?<tzDate>\d*)T(?<tzTime>\d*))/gm;
const summary = /SUMMARY:([A-Z, 0-9a-z]*)/gm;
const rRule = /RRULE:(.*)/gm;

const rFreq = /FREQ=([A-Z]*)/gm;
const rWKST = /WKST=([A-Z]*)/gm;
const rUntil = /UNTIL=([0-9]*)/gm;
const rCount = /COUNT=([0-9]*)/gm;
const rByDay = /BYDAY=([A-Z,]*)/gm;
const rByMonthDay = /BYMONTHDAY=([0-9,]*)/gm;


function findEventData(str) {
    let tmp = {};

    // Extraer el resumen del evento
    tmp.summary = new RegExp(summary).exec(str)[1];

    // Manejar el inicio del evento (DTSTART)
    const _dtStart = new RegExp(dtStart).exec(str);
    if (_dtStart) {
        if (_dtStart[4] && _dtStart[5]) {
            tmp.startDate = _dtStart[4].substring(6, 8) + '/' + _dtStart[4].substring(4, 6) + '/' + _dtStart[4].substring(0, 4);
            tmp.startTime = _dtStart[5].substring(0, 2) + ':' + _dtStart[5].substring(2, 4);
            tmp.startDateTime = new Date(_dtStart[4].substring(0, 4) + '/' + _dtStart[4].substring(4, 6) + '/' + _dtStart[4].substring(6, 8) + " " + tmp.startTime);
        } else {
            tmp.startDate = _dtStart[2].substring(6, 8) + '/' + _dtStart[2].substring(4, 6) + '/' + _dtStart[2].substring(0, 4);
            tmp.startTime = '00:00';
            tmp.startDateTime = new Date(_dtStart[2].substring(0, 4) + '/' + _dtStart[2].substring(4, 6) + '/' + _dtStart[2].substring(6, 8) + " " + tmp.startTime);
        }
    }

    // Manejar el final del evento (DTEND)
    const _dtEnd = new RegExp(dtEnd).exec(str);
    if (_dtEnd) {
        if (_dtEnd[4] && _dtEnd[5]) {
            tmp.endDate = _dtEnd[4].substring(6, 8) + '/' + _dtEnd[4].substring(4, 6) + '/' + _dtEnd[4].substring(0, 4);
            tmp.endTime = _dtEnd[5].substring(0, 2) + ':' + _dtEnd[5].substring(2, 4);
            tmp.endDateTime = new Date(_dtEnd[4].substring(0, 4) + '/' + _dtEnd[4].substring(4, 6) + '/' + _dtEnd[4].substring(6, 8) + " " + tmp.endTime);
        } else {
            tmp.endTime = _dtEnd[5] || '23:59';
            if (_dtEnd[2] && !_dtEnd[4]) {
                tmp.endDate = _dtEnd[2].substring(6, 8) + '/' + _dtEnd[2].substring(4, 6) + '/' + _dtEnd[2].substring(0, 4);
                tmp.endDateTime = new Date(_dtEnd[2].substring(0, 4) + '/' + _dtEnd[2].substring(4, 6) + '/' + _dtEnd[2].substring(6, 8) + " " + tmp.endTime);
            } else {
                tmp.endDate = '';
                tmp.endDateTime = new Date(tmp.startDateTime);
                tmp.endDateTime.setHours(23);
                tmp.endDateTime.setMinutes(59);
            }
        }
    }

    // Manejar la regla de recurrencia (RRULE)
    const _rRule = new RegExp(rRule).exec(str);
    if (_rRule) {
        tmp.rules = {};
        tmp.rules.frequency = new RegExp(rFreq).exec(_rRule[1])[1];

        const WKST = new RegExp(rWKST).exec(_rRule[1]);
        tmp.rules.WKST = WKST ? WKST[1] : '';

        const until = new RegExp(rUntil).exec(_rRule[1]);
        tmp.rules.until = until ? new Date(until[1] * 1000) : '';

        const count = new RegExp(rCount).exec(_rRule[1]);
        tmp.rules.count = count ? count[1] : '';

        tmp.rules.endMode = 0;
        if (tmp.rules.until) {
            tmp.rules.endMode = 1;
        } else if (tmp.rules.count) {
            tmp.rules.endMode = 2;
        }

        const days = new RegExp(rByDay).exec(_rRule[1]);
        tmp.rules.days = days ? days[1].split(",") : '';

        const monthDay = new RegExp(rByMonthDay).exec(_rRule[1]);
        tmp.rules.monthDay = monthDay ? monthDay[1] : '';
    } else {
        tmp.rules = false;
    }

    return tmp;
}

function findEvents(str) {
    const regex = /BEGIN:VEVENT([\w\W]*?)END:VEVENT/gm;

    let m;
    let tmp = [];

    while ((m = regex.exec(str)) !== null) {
        tmp.push(findEventData(m[0]));

        // Esto es necesario para evitar bucles infinitos con coincidencias de ancho cero
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
    }

    return tmp;
}

export function parseIcs(data) {
    return findEvents(data);
}

function formatDateToICS(date) {
    return date.getFullYear() +
        String(date.getMonth() + 1).padStart(2, '0') +
        String(date.getDate()).padStart(2, '0') + 'T' +
        String(date.getHours()).padStart(2, '0') +
        String(date.getMinutes()).padStart(2, '0') +
        String(date.getSeconds()).padStart(2, '0');
}



export function toIcs(data) {
    let str = 'BEGIN:VCALENDAR\n' +
        'VERSION: 2.0\n' +
        'PRODID:-//Tarkan//KoreAG//EN\n';

    data.forEach((d) => {
        const dtStart = new Date(d.startDateTime);
        const dtEnd = new Date(d.endDateTime);
        const strStart = formatDateToICS(dtStart);
        const strEnd = formatDateToICS(dtEnd);

        str += 'BEGIN:VEVENT\n' +
            'DTSTART;TZID=America/Sao_Paulo:' + strStart + '\n' +
            'DTEND;TZID=America/Sao_Paulo:' + strEnd + '\n';

        if (d.rules) {
            str += 'RRULE:FREQ=' + d.rules.frequency + ';';

            if (d.rules.frequency === 'WEEKLY') {
                str += 'BYDAY=' + d.rules.days.join(",") + ';';
            } else if (d.rules.frequency === 'MONTHLY') {
                str += 'BYMONTHDAY=' + dtStart.getDate() + ';';
            }

            if (d.rules.endMode === 1) {
                const tmp = d.rules.until.toISOString().replaceAll('-', '').split("T");
                str += 'UNTIL=' + tmp[0] + ';';
            } else if (d.rules.endMode === 2) {
                str += 'COUNT=' + d.rules.count + ';';
            }

            str += '\n';
        }

        str += 'DTSTAMP:20220128T131146Z\n' +
            'UID:4ttr41esn4jnde6hfa80q7qq7p@google.com\n' +
            'CREATED:20220128T131120Z\n' +
            'DESCRIPTION:\n' +
            'LAST-MODIFIED:20220128T131120Z\n' +
            'LOCATION:\n' +
            'SEQUENCE:0\n' +
            'STATUS:CONFIRMED\n' +
            'SUMMARY:' + d.summary + '\n' +
            'TRANSP:OPAQUE\n' +
            'BEGIN:VALARM\n' +
            'ACTION:DISPLAY\n' +
            'DESCRIPTION:Reminder\n' +
            'TRIGGER:-PT10M\n' +
            'END:VALARM\n' +
            'END:VEVENT\n';
    });

    str += 'END:VCALENDAR';

    return str;
}
